import { Headphones, FileText, Zap, Sparkles, Globe } from "lucide-react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { useAuth } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"

export default function LandingPage() {
  const navigate = useNavigate()
  const { currentUser, logout } = useAuth();
  const handleStart = () => {
    if (currentUser){
    navigate('/convert')
    }
    else{
      navigate('/login')
    }
  }

  const handleStartFreeTrial = () => {
    if (currentUser){
      navigate('/convert')
      }
      else{
        navigate('/login')
      }
  }

  
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="py-20 px-4 md:px-6 lg:px-8">
          <div className="container mx-auto max-w-6xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div className="space-y-6">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-purple-800 leading-tight">
                  Transform Your Documents into Engaging Podcasts
                </h1>
                <p className="text-lg md:text-xl text-gray-600">
                  Effortlessly convert any document into high-quality podcasts in multiple languages. Save time, reach wider audiences, and make your content more accessible globally.
                </p>
                <button onClick={handleStart} className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-full text-lg transition duration-300 ease-in-out">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-20 px-4 md:px-6 lg:px-8 bg-purple-50">
          <div className="container mx-auto max-w-6xl">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-purple-800 mb-12">
              Why Choose Our Multilingual Podcast Generator?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { icon: <Zap className="h-10 w-10 text-purple-600" />, title: "Lightning Fast", description: "Generate podcasts in minutes" },
                { icon: <Headphones className="h-10 w-10 text-purple-600" />, title: "High Quality Audio", description: "Crystal clear, professional sound" },
                { icon: <Globe className="h-10 w-10 text-purple-600" />, title: "Multiple Languages", description: "Create podcasts in various languages" },
                { icon: <Sparkles className="h-10 w-10 text-purple-600" />, title: "AI-Powered", description: "Smart text-to-speech conversion" },
              ].map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-semibold text-purple-800 mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Language Support Section */}
        <section className="py-20 px-4 md:px-6 lg:px-8 bg-purple-50">
          <div className="container mx-auto max-w-4xl">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-purple-800 mb-10">
              Expand Your Reach with Multilingual Podcasts
            </h2>
            <p className="text-lg text-gray-700 text-center mb-8">
              Harness the power of our advanced AI technology to generate podcasts in multiple languages from a single document.
              Reach a global audience and effortlessly overcome language barriers.
            </p>
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-purple-700 mb-4">Supported Languages</h3>
              <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-gray-800">
                {["English", "Spanish", "French", "German", "Chinese", "Japanese", "Arabic", "Hindi"].map((language, index) => (
                  <li key={index} className="flex items-center text-lg">
                    <Globe className="h-5 w-5 text-purple-600 mr-2" />
                    <span>{language}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>


        {/* CTA Section */}
        <section className="py-20 px-4 md:px-6 lg:px-8 bg-purple-50">
          <div className="container mx-auto max-w-4xl text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-6">
              Ready to Turn Your Documents into Multilingual Podcasts?
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Join thousands of content creators who are expanding their global reach with our multilingual podcast generator.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
              <button onClick={handleStartFreeTrial} className="w-full sm:w-auto bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full transition duration-300 ease-in-out">
                Start Free Trial
              </button>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  )
}