import React, { useState } from 'react'
import { useAuth } from '../context/AuthContext'
import { Menu, X } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

// Custom Button component
const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className, ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/')
    } catch (error) {
      console.error("Error logginh out:", error)
    }
  }
  const handleFAQ = () => {
    navigate('/faq')
  }

  const handleAboutMe = () => {
    navigate('/about_me')
  }



  const handleClick = () => {
    navigate('/')
  }

  const handleAuthClick = () => {
    if (currentUser) {
      handleSignOut()
    }
    else {
      navigate('/login')
    }
  }

  const handleGenerate = () => {
    if (currentUser) {
      navigate('/convert')
    }
    else {
      navigate('/login')
    }
  }

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center" onClick={handleClick}>
            {/* Replace with your actual logo */}
            <svg
              className="h-8 w-auto text-purple-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <span className="ml-2 text-xl font-bold text-purple-600">PodcastAI</span>
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <Button
              onClick={handleGenerate}
              className="text-purple-600 hover:text-purple-800 hover:bg-purple-100"
            >
              Generate
            </Button>
            <Button
              onClick={handleFAQ}
              className="text-purple-600 hover:text-purple-800 hover:bg-purple-100"
            >
              FAQ
            </Button>
            <Button
              onClick={handleAboutMe}
              className="text-purple-600 hover:text-purple-800 hover:bg-purple-100"
            >
              About Me
            </Button>
            <Button
              onClick={handleAuthClick}
              className="text-purple-600 hover:text-purple-800 hover:bg-purple-100"
            >
              {currentUser ? 'Logout' : 'Login'}
            </Button>
          </div>

          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-purple-600 hover:text-purple-800 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} sm:hidden`}>
        <div className="pt-2 pb-3 space-y-1">
          <Button
            onClick={handleGenerate}
            className="w-full text-left text-purple-600 hover:text-purple-800 hover:bg-purple-100"
          >
            Generate
          </Button>
          <Button
            onClick={handleFAQ}
            className="w-full text-left text-purple-600 hover:text-purple-800 hover:bg-purple-100"
          >
            FAQ
          </Button>
          <Button
            onClick={handleAboutMe}
            className="w-full text-left text-purple-600 hover:text-purple-800 hover:bg-purple-100"
          >
            About Me
          </Button>
          <Button
            onClick={handleAuthClick}
            className="w-full text-left text-purple-600 hover:text-purple-800 hover:bg-purple-100"
          >
            {currentUser ? 'Logout' : 'Login'}
          </Button>
        </div>
      </div>

    </nav>
  )
}