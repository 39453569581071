import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function TermsOfServicePage() {
  return (
    <div className="min-h-screen flex flex-col bg-white bg-purple-50">
        {/* Your Navbar component will go here */}
        <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8 bg-purple-50">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-purple-900 mb-8 text-center">Terms of Service</h1>
          
          <div className="bg-white rounded-lg shadow-lg p-8 space-y-6">
            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">1. Acceptance of Terms</h2>
              <p className="text-gray-700">
                By accessing or using PodcastAI, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">2. Description of Service</h2>
              <p className="text-gray-700">
                PodcastAI provides an AI-powered platform for converting lecture notes into podcast content. We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">3. User Responsibilities</h2>
              <p className="text-gray-700">
                You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">4. Intellectual Property</h2>
              <p className="text-gray-700">
                The content, features, and functionality of PodcastAI are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">5. Limitation of Liability</h2>
              <p className="text-gray-700">
                PodcastAI shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
              </p>
            </section>


            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">7. Changes to Terms</h2>
              <p className="text-gray-700">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">8. Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about these Terms, please contact us at raghav_ahuja@sfu.ca
              </p>
            </section>
          </div>
        </div>
      </main>

      {/* Footer placeholder */}
      <div className="h-16 bg-purple-600">
        {/* Your Footer component will go here */}
        <Footer />
      </div>
    </div>
  )
}