import React from 'react';
import styled from 'styled-components';

// Styled components for button, image, and text
const Button = styled.a`
  line-height: 1.5;
  height: 3.5rem;
  display: inline-flex;
  align-items: center;
  color: white;
  background-color: #BD5FFF;
  border-radius: 30px;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  transition: 0.3s all linear;
  font-family: 'Cookie', cursive;
  text-decoration: none;

  &:hover, &:active, &:focus {
    box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
    opacity: 0.9;
    color: #ffffff;
    background-color: #a84aff;
  }
`;

const Image = styled.img`
  height: 2rem;
  width: 2rem;
  margin-right: 10px;
`;

const Text = styled.span`
  font-size: 1.5rem;
`;

function Coffee() {
  return (
    <Button target="_blank" href="https://buymeacoffee.com/raghavahuja">
      <Image src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" />
      <Text>Buy me a coffee</Text>
    </Button>
  );
}

export default Coffee;
