import React from 'react'
import { Mail, Linkedin, Twitter, Github, WebhookIcon } from 'lucide-react'
import myImg from './Assets/avatar.svg'
import Footer from './Footer'
import Navbar from './Navbar'
import Coffee from './Coffee'

export default function AboutMePage() {

  return (
    <div className="min-h-screen flex flex-col bg-white">
        {/* Your Navbar component will go here */}
        <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8 bg-purple-50">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-purple-900 mb-8 text-center">About Me</h1>
          
          <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
            <img 
              src={myImg}
              alt="Your Name" 
              className="w-48 h-48 rounded-full mx-auto mb-6"
            />
            <h2 className="text-2xl font-semibold text-purple-800 text-center mb-4">Raghav Ahuja</h2>
            <p className="text-gray-600 text-center mb-6">
              Software Developer | AI Enthusiast | Lifelong Learner
            </p>
            <p className="text-gray-700 mb-6">
              Hello! I'm the creator of PodcastAI, an innovative platform that transforms lecture notes into engaging podcasts. With a background in AI and a passion for education, I'm on a mission to make learning more accessible and enjoyable for everyone.
            </p>
            <p className="text-gray-700 mb-6">
              When I'm not working on PodcastAI, you can find me exploring the latest developments in AI, contributing to open-source projects, or enjoying a good book. I'm always eager to connect with fellow tech enthusiasts and learners!
            </p>
            <Coffee />
          </div>

          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-purple-800 mb-6">Connect with Me</h2>
            <div className="space-y-4">
            <a href="https://raghavahuja2801.github.io" target="_blank" rel="noopener noreferrer" className="flex items-center text-purple-600 hover:text-purple-800">
                <WebhookIcon className="w-6 h-6 mr-2" />
                Portfolio Website
              </a>
              <a href="mailto:raghav_ahuja@sfu.ca" className="flex items-center text-purple-600 hover:text-purple-800">
                <Mail className="w-6 h-6 mr-2" />
                raghav_ahuja@sfu.ca
              </a>
              <a href="https://www.linkedin.com/in/ahuja-raghav/" target="_blank" rel="noopener noreferrer" className="flex items-center text-purple-600 hover:text-purple-800">
                <Linkedin className="w-6 h-6 mr-2" />
                LinkedIn Profile
              </a>
              <a href="https://www.linkedin.com/in/ahuja-raghav/" target="_blank" rel="noopener noreferrer" className="flex items-center text-purple-600 hover:text-purple-800">
                <Twitter className="w-6 h-6 mr-2" />
                @ahuja-raghav
              </a>
              <a href="https://github.com/raghavahuja2801" target="_blank" rel="noopener noreferrer" className="flex items-center text-purple-600 hover:text-purple-800">
                <Github className="w-6 h-6 mr-2" />
                GitHub Profile
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer placeholder */}
      <div className="h-16 bg-purple-600">
        {/* Your Footer component will go here */}
        <Footer />
      </div>
    </div>
  )
}