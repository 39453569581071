export default function Footer() {
    return (
      <footer className="bg-purple-800 text-white py-8 px-4 md:px-6 lg:px-8">
        <div className="container mx-auto max-w-6xl">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p>&copy; {new Date().getFullYear()} PodcastAI . All rights reserved.</p>
            <nav className="flex gap-6 mt-4 md:mt-0">
              <a href="/privacy" className="hover:text-purple-200 transition duration-300 ease-in-out">Privacy Policy</a>
              <a href="/tos" className="hover:text-purple-200 transition duration-300 ease-in-out">Terms of Service</a>
              <a href="/about_me" className="hover:text-purple-200 transition duration-300 ease-in-out">Contact</a>
            </nav>
          </div>
        </div>
      </footer>
    )
  }