'use client'
import React, { useState, useEffect, useCallback, lazy, Suspense, useMemo } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, analytics } from '../firebase';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import Navbar from './Navbar';
import Footer from './Footer';
import { Upload, FileText, Headphones, X, Clock } from 'lucide-react';
import { logEvent } from 'firebase/analytics';

const AudioPlayer = lazy(() => import('./AudioPlayer'));

export default function MainPage() {
  const [isConverting, setIsConverting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [podcast, setPodcast] = useState(null);
  const [file, setFile] = useState(null);
  const [filePreviewURL, setFilePreviewURL] = useState(null);
  const [language, setLanguage] = useState('english')
  const [theme, setTheme] = useState('casual')
  const { currentUser, userProfile, loading } = useAuth();

  useEffect(() => {
    // Cleanup preview URL on component unmount
    return () => {
      URL.revokeObjectURL(filePreviewURL);
    };
  }, [filePreviewURL]);

  const handleFileChange = useCallback((event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      URL.revokeObjectURL(filePreviewURL); // Clear previous URL
      setFilePreviewURL(URL.createObjectURL(selectedFile)); // New URL for preview
    }
  }, [filePreviewURL]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const selectedFile = event.dataTransfer.files[0];
      setFile(selectedFile);
      URL.revokeObjectURL(filePreviewURL); // Clear previous URL
      setFilePreviewURL(URL.createObjectURL(selectedFile)); // New URL for preview
    }
  }, [filePreviewURL]);

  const handleConvert = async () => {
    if (!file || isConverting) return; // Avoid redundant requests
    setIsConverting(true); // Start loading animation

    const formData = new FormData();
    formData.append('notes', file);
    formData.append('uid', currentUser.uid);
    formData.append('language', language);
    formData.append('theme', theme);

    // Log the event with username and filename
    logEvent(analytics, "podcast_conversion_attempt", {
      username: userProfile?.name || "Anonymous", // replace displayName with the correct username property
      filename: file.name,
    });

    try {
      const response = await axios.post('https://peaceful-beyond-85209-08a7406644f8.herokuapp.com/api/upload', formData);
      const initialPodcastData = response.data.podcastData;
      console.log("Podcast initiated:", initialPodcastData);
      listenForPodcastUpdate(initialPodcastData.id);
    } catch (error) {
      console.error('Error initiating podcast conversion:', error);
    }
  };

  // Function to listen for Firestore updates on podcast status
  const listenForPodcastUpdate = (podcastId) => {
    const userDocRef = doc(db, 'user_data', currentUser.uid);

    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      const podcasts = docSnapshot.data().podcasts || {};
      const updatedPodcast = podcasts[podcastId];

      if (updatedPodcast) {
        if (updatedPodcast.status === 'completed') {
          setPodcast(updatedPodcast);
          console.log("Podcast generation completed:", updatedPodcast);
          setIsConverting(false);
          setFile(null); // Clear file after use
          URL.revokeObjectURL(filePreviewURL); // Cleanup preview URL
          unsubscribe(); // Stop listening once completed
        } else if (updatedPodcast.status === 'failed') {
          console.error("Podcast generation failed.");
          // Show the specific error message if available
          const errorMessage = updatedPodcast.error || "Failed to generate podcast!";
          alert(errorMessage);
          setIsConverting(false);
          unsubscribe(); // Stop listening on failure
        }
      }
    }, (error) => {
      console.error("Error listening for podcast updates:", error);
    });

    return unsubscribe;
  };

  const renderedPodcasts = useMemo(() => {
    const podcastsArray = userProfile?.podcasts
      ? Array.isArray(userProfile.podcasts)
        ? userProfile.podcasts
        : Object.values(userProfile.podcasts) // Convert object to array if needed
      : [];

    return podcastsArray
      .filter((podcast) => podcast.status === 'completed')
      .map((podcast) => (
        <li key={podcast.id} className="py-4">
          <div className="flex items-center space-x-4">
            <Headphones className="h-6 w-6 text-purple-500" />
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-purple-900 truncate">{podcast.title}</p>
              <p className="text-sm text-purple-500 flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                {podcast.createdAt}
              </p>
            </div>
            <button
              onClick={() => setPodcast(podcast)}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Play
            </button>
          </div>
        </li>
      ));
  }, [userProfile?.podcasts]);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="min-h-screen bg-purple-50">
        <Navbar />
        <div className="py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl font-bold text-purple-900 mb-6">Welcome, {userProfile?.name}</h1>

            {/* File Upload Section */}
            <div className="bg-white shadow-xl rounded-lg overflow-hidden mb-8">
              <div className="px-4 py-5 sm:p-6">
                <h2 className="text-2xl font-bold text-purple-900 mb-4">Upload Your Lecture Notes</h2>
                <div
                  className={`border-2 border-dashed rounded-lg p-12 text-center ${isDragging ? 'border-purple-500 bg-purple-100' : 'border-purple-300'}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {file ? (
                    <div className="flex items-center justify-center space-x-4">
                      <FileText className="h-8 w-8 text-purple-500" />
                      <span className="text-lg font-medium text-purple-900">{file.name}</span>
                      <button onClick={() => setFile(null)} className="text-purple-500 hover:text-purple-700">
                        <X className="h-6 w-6" />
                      </button>
                    </div>
                  ) : (
                    <div>
                      <label htmlFor="file-upload" className="cursor-pointer">
                        <Upload className="mx-auto h-12 w-12 text-purple-400" />
                      </label>
                      <p className="mt-2 text-sm text-purple-600">
                        Drag and drop your file here, or{' '}
                        <label htmlFor="file-upload" className="ml-1 font-medium text-purple-600 hover:text-purple-500 cursor-pointer">
                          browse
                        </label>
                      </p>
                      <p className="mt-1 text-xs text-purple-500">PDF, DOCX, TXT up to 10MB</p>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                        accept=".pdf,.docx,.txt"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                    Podcast Language
                  </label>
                  <select
                    id="language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  >
                    <option value="english">English</option>
                    <option value="spanish">Spanish</option>
                    <option value="german">German</option>
                    <option value="chinese">Chinese</option>
                    <option value="japanese">Japanese</option>
                    <option value="arabic">Arabic</option>
                    <option value="hindi">Hindi</option>
                    <option value="telegu">Telugu</option>
                    <option value="gujrati">Gujrati</option>
                    <option value="punjabi">Punjabi</option>
                    <option value="bengali">Bengali</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="theme" className="block text-sm font-medium text-gray-700 mb-1">
                    Podcast Theme
                  </label>
                  <select
                    id="theme"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  >
                    <option value="casual">Casual</option>
                    <option value="professional">Professional</option>
                    <option value="educational">Educational</option>
                    <option value="entertaining">Entertaining</option>
                  </select>
                </div>

                <div className="mt-4">
                  <button
                    onClick={handleConvert}
                    disabled={!file || isConverting}
                    className={`w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white ${file && !isConverting ? 'bg-purple-600 hover:bg-purple-700' : 'bg-purple-300 cursor-not-allowed'
                      }`}
                  >
                    {isConverting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Converting...
                      </>
                    ) : (
                      <>
                        <Headphones className="mr-2 h-5 w-5" />
                        Convert to Podcast
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* Audio Player Section */}
            {podcast && (
              <div className="mt-8 bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-2xl font-bold text-purple-900 mb-4">Your Podcast is Ready</h2>
                  <Suspense fallback={<div>Loading Player...</div>}>
                    <AudioPlayer podcast={podcast} />
                  </Suspense>
                </div>
              </div>
            )}

            {/* Previously Generated Podcasts Section */}
            <div className="mt-8 bg-white shadow-xl rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                <h2 className="text-2xl font-bold text-purple-900 mb-4">Previously Generated Podcasts</h2>
                <ul className="divide-y divide-purple-200">{renderedPodcasts}</ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
