// client/src/App.js
import {React, useEffect} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import HomePage from './components/HomePage';
import AuthPage from './components/Login';
import MainPage from './components/MainPage';
import UserInfoPage from './components/UserSetup';
import { AuthProvider } from './context/AuthContext';
import FAQPage from './components/FAQ';
import AboutMePage from './components/AboutMe';
import TermsOfServicePage from './components/Terms-of-service';
import PrivacyPolicyPage from './components/Privacy-policy';
import { analytics } from './firebase';
import { logEvent } from "firebase/analytics";

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/login',
    element: <AuthPage />
  },
  {
    path: '/setup',
    element: <UserInfoPage />
  },
  {
    path: '/convert',
    element: <MainPage />
  },
  {
    path: '/faq',
    element: <FAQPage />
  },
  {
    path: '/about_me',
    element: <AboutMePage />
  },
  {
    path: '/tos',
    element: <TermsOfServicePage />
  },
  {
    path: '/privacy',
    element: <PrivacyPolicyPage />
  }
]);

function App() {
  useEffect(() => {
    // Log the custom event for app open
    logEvent(analytics, "app_open");
  }, []);
    return (
      <AuthProvider>
      <RouterProvider router={router} />
      </AuthProvider>
    );
}

export default App;