// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, setDoc } from 'firebase/firestore';
// import styled from 'styled-components';
// import { auth, db } from '../firebase';


// const Label = styled.label`
//   /* margin-bottom: 10px; */
//   width: 20%;
//   justify-content: center;
//   font-weight: bold;
//   margin-right: 10px;
//   align-items: left;
// `;

// const Input = styled.input`
//   padding: 8px;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const Button = styled.button`
//   padding: 10px;
//   font-size: 16px;
//   width: auto;
//   background-color: #007bff;
//   color: #ffffff;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// `;


// export default function UserInfoPage() {
//   const [user, setUser] = useState(null);
//   const [name, setName] = useState('');
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//       setIsLoading(false);
//       if (!currentUser) {
//         navigate('/login');
//       }
//     });

//     return () => unsubscribe();
//   }, [auth, navigate]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (user && name) {
//       try {
//         console.log("TRYING TO SAVE DATA FOR ",user.uid)
//         const userDocRef = doc(db, 'user_data', user.uid);
//         await setDoc(userDocRef, {
//           Name: name,
//           email: user.email
//         });
//         navigate('/convert');
//       } catch (error) {
//         console.error("Error saving user data:", error);
//         alert("Failed to save user data. Please try again.");
//       }
//     }
//   };

//   if (isLoading) {
//     return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
//   }

//   if (!user) {
//     return null;
//   }

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100">
//       <div className="bg-white p-8 rounded-lg shadow-md w-96">
//         <h1 className="text-2xl font-bold mb-6 text-center">Complete Your Profile</h1>
//         <form onSubmit={handleSubmit} className="space-y-4">
//           <div>
//             <Label htmlFor="name">Your Name</Label>
//             <Input
//               id="name"
//               type="text"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               required
//               placeholder="Enter your name"
//             />
//           </div>
//           <Button type="submit" className="w-full">
//             Save and Continue
//           </Button>
//         </form>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import Navbar from './Navbar'
import Footer from './Footer'

export default function UserInfoPage() {
  const [user, setUser] = useState(null)
  const [name, setName] = useState('')
  const [dob, setDob] = useState('')
  const [bio, setBio] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setIsLoading(false)
      if (!currentUser) {
        navigate('/login')
      }
    })

    return () => unsubscribe()
  }, [navigate])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (user && name) {
      try {
        console.log("TRYING TO SAVE DATA FOR ", user.uid)
        const userDocRef = doc(db, 'user_data', user.uid)
        await setDoc(userDocRef, {
          name,
          email: user.email,
          dob,
          bio
        })
        navigate('/convert')
      } catch (error) {
        console.error("Error saving user data:", error)
        alert("Failed to save user data. Please try again.")
      }
    }
  }

  if (isLoading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>
  }

  if (!user) {
    return null
  }

  return (
    <div className="flex flex-col min-h-screen bg-purple-05">
      {/* Navbar placeholder */}
      <div className="h-16 bg-purple-600">
        {/* Your Navbar component will go here */}
        <Navbar />
      </div>

      <div className="flex-grow bg-purple-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-3xl font-bold text-center text-purple-800 mb-6">Complete Your Profile</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Enter your full name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                  Date of Birth
                </label>
                <input
                  id="dob"
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                  Bio
                </label>
                <textarea
                  id="bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Tell us about yourself"
                  className="w-full h-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Save and Continue
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer placeholder */}
      <div className="h-16 bg-purple-600">
       <Footer />
      </div>
    </div>
  )
}