import { getFirestore, doc, getDoc } from 'firebase/firestore'

// This function checks if the user profile exists
export default async function checkUserProfile(uid) {
  const db = getFirestore()
  const userDocRef = doc(db, 'user_data', uid)
  const docSnapshot = await getDoc(userDocRef)
  return docSnapshot.exists()
}

