import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import Navbar from './Navbar'
import Footer from './Footer'

const faqs = [
  {
    question: "What is PodcastAI?",
    answer: "PodcastAI is an innovative platform that converts your lecture notes into engaging podcast content using advanced AI technology."
  },
  {
    question: "How does PodcastAI work?",
    answer: "Simply upload your lecture notes, select your preferred language and theme, and our AI will transform your content into a podcast-ready format."
  },
  {
    question: "What file formats are supported?",
    answer: "PodcastAI supports PDF, DOCX, and TXT file formats for uploading lecture notes."
  },
  {
    question: "In what languages can I create podcasts?",
    answer: "Currently, PodcastAI supports podcast creation in English, French, Spanish and Hindi. We're constantly working to add more languages."
  },
  {
    question: "Can I customize the podcast theme?",
    answer: "Yes, you can choose from various themes such as Casual, Professional, and Educational to match your podcast's tone and audience."
  },
  {
    question: "Is there a file size limit?",
    answer: "Yes, the maximum file size for uploads is 5MB. If your file is larger, consider splitting it into smaller parts."
  },
  {
    question: "How long does it take to convert my notes to a podcast?",
    answer: "Conversion time varies depending on the length and complexity of your notes, but typically takes between 1-3 minutes."
  }
]

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-purple-200">
      <button
        className="flex justify-between items-center w-full py-5 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-purple-900">{question}</span>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-purple-600" />
        ) : (
          <ChevronDown className="w-5 h-5 text-purple-600" />
        )}
      </button>
      {isOpen && (
        <div className="pb-5 text-gray-600">
          <p>{answer}</p>
        </div>
      )}
    </div>
  )
}

export default function FAQPage() {
  return (
    <div className="min-h-screen flex flex-col bg-white bg-purple-50">
        {/* Your Navbar component will go here */}
        <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8 bg-purple-50">
        <h1 className="text-4xl font-bold text-purple-900 mb-8 text-center">Frequently Asked Questions</h1>
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </main>

      {/* Footer placeholder */}
      <div className="h-16 bg-purple-600">
        {/* Your Footer component will go here */}
        <Footer />
      </div>
    </div>
  )
}