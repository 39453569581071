// 'use client'

// import { useState } from 'react'
// import { useAuth } from '../context/AuthContext'
// import { useNavigate } from 'react-router-dom'
// import Footer from './Footer'
// import Navbar from './Navbar'

// export default function AuthPage() {
//   const [email, setEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const { login, signup } = useAuth()
//   const [isLogin, setIsLogin] = useState(true)
//   const navigate = useNavigate()

//   const handleAuth = async (e: React.FormEvent) => {
//     e.preventDefault()
//     try {
//       if (isLogin) {
//         await login(email, password)
//         navigate('/convert')
//       } else {
//         await signup(email, password)
//         navigate('/setup')
//       }
//     } catch (error) {
//       console.error('Authentication error:', error)
//       alert('Authentication failed. Please try again.')
//     }
//   }

//   return (
//     <div className="flex flex-col min-h-screen bg-purple-50">
//       <Navbar />
//       <div className="h-6"></div>

//       <main className="flex-grow flex items-center justify-center px-4 py-12">
//         <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
//           <h1 className="text-3xl font-bold mb-6 text-center text-purple-800">
//             {isLogin ? 'Welcome Back' : 'Create Account'}
//           </h1>
//           <form onSubmit={handleAuth} className="space-y-6">
//             <div>
//               <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
//                 Email
//               </label>
//               <input
//                 id="email"
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 type="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
//               />
//             </div>
//             <button
//               type="submit"
//               className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
//             >
//               {isLogin ? 'Sign In' : 'Sign Up'}
//             </button>
//           </form>
//           <p className="mt-6 text-center text-sm text-gray-600">
//             {isLogin ? "Don't have an account? " : "Already have an account? "}
//             <button
//               className="text-purple-600 hover:text-purple-500 font-medium focus:outline-none focus:underline transition ease-in-out duration-150"
//               onClick={() => setIsLogin(!isLogin)}
//             >
//               {isLogin ? 'Sign Up' : 'Sign In'}
//             </button>
//           </p>
//         </div>
//       </main>
//       <div className="h-6"></div>
//       <Footer />
//     </div>
//   )
// }

import { useState} from 'react'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import checkUserProfile from "../Utils/checkUserProfile"
import Footer from './Footer'
import Navbar from './Navbar'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

export default function AuthPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, signup} = useAuth()
  const [isLogin, setIsLogin] = useState(true)
  const navigate = useNavigate()



  const handleAuth = async (e) => {
    e.preventDefault()
    try {
      if (isLogin) {
        await login(email, password)
        navigate('/convert')
      } else {
        await signup(email, password)
        navigate('/setup')
      }
    } catch (error) {
      console.error('Authentication error:', error)
      alert('Authentication failed. Please try again.')
    }
  }

  const handleGoogleSignIn = async () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
    const user = result.user

    // Check if the user profile exists in your database
    const profileExists = await checkUserProfile(user.uid) // Assuming you have a function for this

    if (profileExists) {
      navigate('/convert')
    } else {
      navigate('/setup')
    }
    } catch (error) {
      console.error('Google sign-in error:', error)
      alert('Google sign-in failed. Please try again.')
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-purple-50">
      <Navbar />
      <div className="h-6"></div>

      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h1 className="text-3xl font-bold mb-6 text-center text-purple-800">
            {isLogin ? 'Welcome Back' : 'Create Account'}
          </h1>
          <form onSubmit={handleAuth} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            >
              {isLogin ? 'Sign In' : 'Sign Up'}
            </button>
          </form>
            <div className="mt-4">
              <button
            onClick={handleGoogleSignIn}
            className="w-full mt-4 py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150 ease-in-out"
          >
            {isLogin ? 'Sign in with Google' : 'Sign up with Google'}
          </button>
            </div>
          
          <p className="mt-6 text-center text-sm text-gray-600">
            {isLogin ? "Don't have an account? " : "Already have an account? "}
            <button
              className="text-purple-600 hover:text-purple-500 font-medium focus:outline-none focus:underline transition ease-in-out duration-150"
              onClick={() => setIsLogin(!isLogin)}
            >
              {isLogin ? 'Sign Up' : 'Sign In'}
            </button>
          </p>
        </div>
      </main>
      <div className="h-6"></div>
      <Footer />
    </div>
  )
}
