import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function PrivacyPolicyPage() {
  return (
    <div className="min-h-screen flex flex-col bg-white bg-purple-50">
        {/* Your Navbar component will go here */}
        <Navbar />

      <main className="flex-grow container mx-auto px-4 py-8 bg-purple-50">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-purple-900 mb-8 text-center">Privacy Policy</h1>
          
          <div className="bg-white rounded-lg shadow-lg p-8 space-y-6">
            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">1. Information We Collect</h2>
              <p className="text-gray-700">
                We collect information you provide directly to us, such as when you create an account, upload content, or contact us. This may include your name, email address, and any other information you choose to provide.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">2. How We Use Your Information</h2>
              <p className="text-gray-700">
                We use the information we collect to provide, maintain, and improve our services, to develop new features, and to protect PodcastAI and our users.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">3. Information Sharing and Disclosure</h2>
              <p className="text-gray-700">
                We do not share, sell, rent, or trade your personal information with third parties for their commercial purposes. We may share information in response to legal requests, to protect our rights, or in the event of a business transfer.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">4. Data Security</h2>
              <p className="text-gray-700">
                We use reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">5. Your Choices</h2>
              <p className="text-gray-700">
                You may update, correct, or delete your account information at any time by logging into your online account or by contacting us. You may also opt out of receiving promotional communications from us by following the instructions in those messages.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">6. Changes to this Policy</h2>
              <p className="text-gray-700">
                We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-purple-800 mb-4">7. Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about this privacy policy, please contact us at raghav_ahuja@sfu.ca.
              </p>
            </section>
          </div>
        </div>
      </main>

      {/* Footer placeholder */}
      <div className="h-16 bg-purple-600">
        {/* Your Footer component will go here */}
        <Footer />
      </div>
    </div>
  )
}